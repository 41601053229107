import { TuiTooltipDirective } from '@clickbar/tailwindui-vue'
import '@clickbar/tailwindui-vue/style.css'
import '@fontsource-variable/inter'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import { Link, createInertiaApp } from '@inertiajs/vue3'
import * as Sentry from '@sentry/vue'
import '@total-typescript/ts-reset'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { trail, route, current } from 'momentum-trail'
import { createApp, h } from 'vue'

import AppLayout from './Layouts/AppLayout.vue'
import SidebarLayout from './Layouts/SidebarLayout.vue'
import '../css/app.css'

import type { DefineComponent } from 'vue'

// eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
const appName = window.document.querySelectorAll('title')[0]?.textContent ?? 'Laravel'

void createInertiaApp({
  title: (title: string) => `${title} - ${appName}`,
  progress: {
    color: '#4B5563',
  },
  resolve: async (name: string) => {
    const page = await resolvePageComponent(
      `./Pages/${name}.vue`,
      import.meta.glob<DefineComponent>('./Pages/**/*.vue'),
    )
    const layouts = [AppLayout, SidebarLayout]
    page.default.layout = page.default.layout ?? layouts

    return page
  },
  setup({ el, App, props, plugin }) {
    const appInstance = createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(autoAnimatePlugin)
      // @ts-expect-error TODO: Upgrade to ziggy typed routes
      .use(trail, { routes: null })
      .directive('tooltip', TuiTooltipDirective)
      .mixin({ methods: { route, current } })
      .component('inertia-link', Link) // Needed for TUI

    Sentry.init({
      enabled: Boolean(import.meta.env.VITE_SENTRY_VUE_ENABLED),
      app: appInstance,
      dsn: import.meta.env.VITE_SENTRY_VUE_DSN ?? '',
      tracesSampleRate: Number.parseFloat(
        import.meta.env.VITE_SENTRY_VUE_TRACES_SAMPLE_RATE ?? '0.0',
      ),

      logErrors: true,
      environment: import.meta.env.VITE_SENTRY_ENVIRONMENT ?? 'failed',

      replaysSessionSampleRate: Number.parseFloat(
        import.meta.env.VITE_SENTRY_VUE_REPLAYS_SESSION_SAMPLE_RATE ?? '0.0',
      ),
      replaysOnErrorSampleRate: Number.parseFloat(
        import.meta.env.VITE_SENTRY_VUE_REPLAYS_ON_ERROR_SAMPLE_RATE ?? '1.0',
      ),

      timeout: 500,
      trackComponents: true,

      integrations: [Sentry.replayIntegration(), Sentry.browserTracingIntegration()],
    })

    appInstance.mount(el)
  },
})

window.route = route
window.current = current
