import { usePage } from '@inertiajs/vue3'
import { computed } from 'vue'

import { assert } from '@/Utils'

export function useCurrentDepartment() {
  const page = usePage()

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return computed(() => page.props?.currentDepartment as App.Data.Department.DepartmentData | null)
}

export function useRequiredCurrentDepartment() {
  const currentDepartment = useCurrentDepartment()

  return computed(() => {
    assert(currentDepartment.value)
    return currentDepartment.value
  })
}
